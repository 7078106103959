import 'aos/dist/aos.css';
import '@/assets/scss/config/modern/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import 'element-plus/dist/index.css';
import { createApp } from 'vue';
import AOS from 'aos';
import BootstrapVue3 from 'bootstrap-vue-3';
import VueFeather from 'vue-feather';
import VueApexCharts from 'vue3-apexcharts';
import { VueQueryPlugin } from '@tanstack/vue-query';
import vClickOutside from 'click-outside-vue3';
import ElementPlus from 'element-plus';
import ru from 'element-plus/es/locale/lang/ru';
import Maska from 'maska';
import { createPinia } from 'pinia';
import App from './App.vue';
import i18n from './i18n';
import router from './router';
import store from './state/store';

const pinia = createPinia();

AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});

createApp(App)
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .use(BootstrapVue3)
  .component(VueFeather.type, VueFeather)
  .use(Maska)
  .use(i18n)
  .use(vClickOutside)
  .use(pinia)
  .use(VueQueryPlugin)
  .use(ElementPlus, {
    locale: ru,
  })
  .mount("#app");
